// src/Quiz.js
import React, { useState, useEffect } from 'react';

function Quiz() {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [error, setError] = useState('');
  const [answerResult, setAnswerResult] = useState(null);

  useEffect(() => {
    // Fetch questions from the API
    fetch('/api/v1/questions') // Replace with your API URL
      .then(response => response.json())
      .then(data => {
        setQuestions(data);
        setLoading(false);
      })
      .catch(error => console.error('Error fetching questions:', error));
  }, []);

  const handleAnswerSelection = (answerId) => {
    setSelectedAnswer(answerId);
    setAnswerResult(null);
  };

  const handleSubmit = () => {
    if (selectedAnswer === null) {
      setError('Please select an answer.');
      return;
    }

    setError('');
    const currentQuestion = questions[currentQuestionIndex];

    // Check if the answer is correct
    fetch('/api/v1/questions/check_answer', { // Replace with your API URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        questionId: currentQuestion.id,
        answerId: selectedAnswer,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setAnswerResult(data.explanation);
        if (data.isCorrect) {
          setScore(score + 1);
        }
      })
      .catch(error => console.error('Error checking answer:', error));
  };

  const handleNext = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setSelectedAnswer(null);
    setAnswerResult(null);
    setError('');
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedAnswer(null);
      setAnswerResult(null);
      setError('');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (currentQuestionIndex >= questions.length) {
    return <div>Your final score is: {score}</div>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div>
      <h1>Coming Soon!</h1>
      <h1>Samples</h1>

      <h2>Question {currentQuestionIndex + 1}</h2>
      <p>{currentQuestion.content}</p>
      <form>
        {currentQuestion.answers.map((answer) => (
          <div key={answer.id}>
            <label>
              <input
                type="radio"
                name="answer"
                value={answer.id}
                checked={selectedAnswer === answer.id}
                onChange={() => handleAnswerSelection(answer.id)}
              />
              {answer.content}
            </label>
          </div>
        ))}
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleSubmit}>Submit</button>
      {answerResult && <p>{answerResult}</p>}
      <div>
        <button onClick={handlePrevious} disabled={currentQuestionIndex === 0}>Previous</button>
        <button onClick={handleNext} disabled={currentQuestionIndex >= questions.length - 1}>Next</button>
      </div>
      <p>Score: {score}</p>
    </div>
  );
}

export default Quiz;
