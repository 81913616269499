// src/About.js
import React from 'react';

function About() {
  return (
    <div>
      <h2>About Professional Certification Hub</h2>
      <p>
        At Professional Certification Hub, our mission is to support professionals who are striving to achieve their Project Management Professional (PMP) certification. We understand the challenges and the dedication required to obtain this prestigious credential, and we're here to help you succeed.
      </p>
      <p>
        Our platform offers a wealth of resources, including comprehensive study guides, practice exams, and expert advice. Whether you're just starting your PMP journey or are on the verge of taking your exam, we provide the tools and support you need to confidently pursue your certification.
      </p>
      <p>
        Achieving PMP certification can open new doors in your career, enhance your project management skills, and increase your earning potential. Join our community of dedicated professionals and take the next step towards your career advancement.
      </p>
    </div>
  );
}

export default About;
