// src/Home.js
import React from 'react';

function Home() {
  return (
    <div>
      <h2>Welcome to Professional Certification Hub</h2>
      <p>
        This website is dedicated to helping professionals achieve their career goals through obtaining professional certifications such as PMP (Project Management Professional). 
      </p>
      <p>
        Certifications like PMP are globally recognized and can significantly boost your career by enhancing your skills, increasing your job opportunities, and potentially leading to higher salaries. Our resources, guides, and community support are here to assist you on your journey to certification success.
      </p>
    </div>
  );
}

export default Home;
