// src/Navigation.js
import React from 'react';
import { Link } from 'react-router-dom';
import { trackPageView } from './analytics';

function Navigation() {
  return (
    <nav>
      <ul>
        <li><Link to="/" onClick={() => trackPageView('/')}>Home</Link></li>
        <li><Link to="/quiz" onClick={() => trackPageView('/quiz')}>Practise Test</Link></li>
        <li><Link to="/about" onClick={() => trackPageView('/about')}>About</Link></li>
        <li><Link to="/contact" onClick={() => trackPageView('/contact')}>Contact</Link></li>
      </ul>
    </nav>
  );
}

export default Navigation;
