// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Quiz from './Quiz';
import { trackPageView } from './analytics';

function App() {
  useEffect(() => {
    trackPageView(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <Navigation />
        <div className="App-layout">
        <aside className="App-sidebar-left">
          <div className="ad"></div>
        </aside>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <aside className="App-sidebar-right">
          <div className="ad"></div>
        </aside>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
